import { HoverLink } from "common/components/HoverLink/HoverLink"

import { Wrapper, Name, Separator } from "./footer.styles"

export const Footer = () => (
  <Wrapper>
    <Name>
      <span>ALE</span>
      <span style={{ fontWeight: 800 }}>ŁADNIE</span>
      <span>.STUDIO</span>
    </Name>
    <Separator hidden />
    <HoverLink href="mailto:kontakt@aleladnie.studio">
      kontakt@aleladnie.studio
    </HoverLink>
    <Separator />
    <HoverLink href="tel:+48660254828">+48 660 254 828</HoverLink>
  </Wrapper>
)
