import logo from "logo.svg"

import { Wrapper } from "./navbar.styles"
import { Logo } from "./navbar.styles"

export const Navbar = () => {
  return (
    <Wrapper>
      <Logo src={logo} alt="AleŁadnie.Studio - architektura wnętrz" />

      {/* <MailLink href="mailto:kontakt@aleladnie.studio">
          kontakt@aleladnie.studio
        </MailLink> */}
    </Wrapper>
  )
}
