import React, { useEffect, useState } from "react"

import { Slider } from "Slider/Slider"
import { Navbar } from "Navbar/Navbar"
import { Footer } from "Footer/Footer"

function App() {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const loaderElement = document.querySelector(".loader-container")

    if (loaderElement) {
      loaderElement.remove()
      setLoading(!isLoading)
    }
  }, [isLoading])

  if (isLoading) {
    return null
  }

  return (
    <>
      <Navbar />
      <Slider />
      <Footer />
    </>
  )
}

export default App
