import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./swiper.css"

import { useMediaQuery } from "common/hooks/useMediaQuery"
import { PHOTOS } from "./slider.consts"
import { Wrapper } from "./slider.styles"

export const Slider = () => {
  const isMobile = useMediaQuery("(max-width: 600px)")
  const isTablet = useMediaQuery("(max-width: 900px)")

  const slidesPerView = isMobile ? 1 : isTablet ? 2 : 3

  return (
    <Wrapper>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={2}
        className="mySwiper"
        loop
        // effect="fade" // ??? <-
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[
          Autoplay,
          EffectFade,
          // Pagination
        ]}
      >
        {PHOTOS.map((photo) => (
          <SwiperSlide>
            <img src={photo} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}
