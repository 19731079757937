import { HoverLink } from 'common/components/HoverLink/HoverLink';
import styled from 'styled-components';

export const Wrapper = styled.footer`
  align-items: center;
  column-gap: 10px;
  display: flex;
  font-size: 16px;
  height: 60px;
  justify-content: center;

  @media (min-width: 600px) {
   font-size: 14px;
   justify-content: center;   
  }
  
  ${HoverLink} {
    font-size: 16px;
    
    &::before {
      height: 1px;
      border-radius: 1px;
    }

    @media (min-width: 600px) {
     font-size: 14px;
     letter-spacing: 1px;
    }
  } 
`

export const Name = styled.div`
  letter-spacing: 1px;
  display: none;

  @media (min-width: 600px) {
    display: block;     
  }
`

export const Separator = styled.span`
  display: ${props => props.hidden ? 'none' : 'flex'};

  @media (min-width: 600px) {    
    width: 1px;
    display: flex;
    height: 20px;
    background-color: #888;
  }
`