import styled from "styled-components"

export const HoverLink = styled.a`
  color: var(--link-color);
  position: relative;
  text-decoration: none;

  &::before {
    background-color: var(--link-color);
    border-radius: 2px;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    width: 100%;
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
`
