import { HoverLink } from 'common/components/HoverLink/HoverLink';
import styled from 'styled-components';

export const Logo = styled.img`
  transition: all 0.3s;
  width: 130px;
  
  @media (min-width: 600px) {
   width: 200px;
  }

  /* &:hover {  
    transition: .3s; 
    transform: perspective(400px) rotateY(45deg);
  } */
`;

export const Wrapper = styled.section`
  background-color: transparent;
  left: 0;
  margin: 20px 0;
  position: absolute;
  top: 0;  
  z-index: 99;
`

export const MailLink = styled(HoverLink)`  
  font-size: 1rem;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;
