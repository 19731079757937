export const PHOTOS = [

  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/1__optyk2_zguz2h.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/2_optyk1_gzhph8.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/3_optyk3_x2nn5d.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/4_grabowka8_qb1fsj.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/5_grabowka7_fz939c.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/6_grabowka9_mccd0r.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/7_grabowka9a_kv9ife.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/8_grabowka6_ofvdjh.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/9_grabowka10_k2sy0i.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/10_grabowka11_yy4cxv.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/11_rocha2_yqqape.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/12_rocha3_tubjuk.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/13_rocha4_c5mnpz.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/14_rocha5_u1wqbk.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/15_rocha1_bnkwfr.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/16_sudecka1_vmlons.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/17_sudecka2_yclya8.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/18_sudecka3_h4qgco.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/19_sudecka9_s4kwyl.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/20_antoniuk6_riy4ak.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/21_antoniuk3_gj3px3.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/22_antoniuk5_ntvztp.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/23_antoniuk7_octc9c.jpg",
  "https://res.cloudinary.com/dxmbu7swf/image/upload/w_853,h_1280,c_fill/projects/24_antoniuk11_cnpdfx.jpg",
]